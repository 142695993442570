
/* #### Generated By: http://www.cufonfonts.com #### */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');

@font-face {
font-family: 'Geometria';
font-style: normal;
font-weight: normal;
src: local('Geometria'), url('../fronts/Geometria.woff') format('woff');
}

@font-face {
font-family: 'Geometria-Medium';
font-style: normal;
font-weight: normal;
src: local('Geometria-Medium'), url('../fronts/Geometria-Medium.woff') format('woff');
}

@font-face {
font-family: 'Geometria-Bold';
font-style: normal;
font-weight: normal;
src: local('Geometria-Bold'), url('../fronts/Geometria-Bold.woff') format('woff');
}





