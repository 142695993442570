$font-medium: "Geometria-Medium";
$font-bold: "Geometria-Bold";
$primary-color: #324755;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-medium;
  font-size: 15px;
  color: $primary-color;
  line-height: 1.6;
}

#root {
  height: 100%;

  >div {
    height: 100%;

    >div {
      height: 100%;
    }
  }
}

a:hover {
  color: #64c9d1;
  text-decoration: none;
}

::placeholder {
  color: #cecece !important;
}

::-ms-input-placeholder {
  color: #cecece !important;
}

input:focus {
  box-shadow: none !important;
  border-color: #64c9d1 !important;
}

.container_costume {
  display: flex;
  margin: auto;
  display: flex;
  flex-direction: column;

  .icon {
    position: fixed;
    background-color: #fff;
    z-index: 3;
  }

  .button {
    margin-bottom: 30px;
  }
}

.colm_costume {
  width: 100%;
  padding: 0 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.button {
  text-align: center;
  margin-bottom: 10px;

  button {
    width: 132px;
    text-align: center;
    border-radius: 50px;
    height: 44px;
    border: 0;
    background: #64c9d1;
    color: #fff;
    text-transform: uppercase;
    margin: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    font-family: $font-bold;
    letter-spacing: 2px;
    outline: none;
  }

  .disable {
    background: rgba(0, 0, 0, 0.1);
    color: #b7b7b7;
  }
}

.logo {
  text-align: center;
}

.login {
  width: 100%;
  padding: 0 15px;

  input {
    border: none;
    border-bottom: 1px solid #dbdbdb;
    padding: 25px 0;
    border-radius: 0;
    letter-spacing: 0.5px;
    box-shadow: none;
    font-size: 14px;
  }

  button {
    margin-top: 20px;
  }

  label {
    letter-spacing: 0.5px;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}

.signup {
  letter-spacing: 0.5px;
  text-align: center;

  p {
    border-bottom: 1px solid $primary-color;
    margin-bottom: 10px;
    font-size: 14px;

    a {
      color: $primary-color;
    }

    span {
      display: block;
    }
  }
}

.socials {
  margin-top: 20px;

  a {
    margin: 10px;
  }
}

.form-group-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  input {
    padding-right: 60px;
  }
}

.forgot {
  font-size: 12px;
  color: $primary-color;
  font-family: "Geometria";
  letter-spacing: 0.5px;
  position: absolute;
  right: 0;

  &:hover {
    text-decoration: none;
  }
}

.colm_info_user {
  justify-content: center;
  position: fixed;
}

.colm_info {
  justify-content: start;
}

.back {
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.icon {
  width: 60px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  cursor: pointer;
  color: $primary-color;
  position: relative;

  &:hover,
  &:focus {
    color: $primary-color;
  }

  .file {
    opacity: 0;
    position: absolute;
    z-index: 2;
    height: 55px;
    width: 60px;
  }
}

.icon-back {
  background-image: url(../images/back.svg);
}

.box_user {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  width: 100%;
  border-radius: 10px;
  margin: 0 0 6rem;

  .alert {
    font-size: 12px;
    position: absolute;
    right: 0;
    background: #fff;
    color: #dc3545;
  }
}

.colm_costume h3 {
  font-family: $font-bold;
  font-size: 18px;
  letter-spacing: 1px;
  color: $primary-color;
  text-align: center;
  line-height: 23px;
  padding: 0 30px;
  margin-top: 10px;

  span {
    border-bottom: 1px solid $primary-color;
  }
}

.box_user .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 23px 20px;
  border-bottom: 1px solid #f0f3f4;
  margin: 0;

  label {
    margin: 0;
    font-family: $font-bold;
  }

  input {
    text-align: right;
    border: none;
    outline: none;
    font-family: $font-medium;
    color: $primary-color;
    background: #fff;
    padding: 0;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
}

.rdtPicker {
  right: 0;
}

.box-info-check {
  width: 100%;
  margin: 1.5rem 0;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: block;
      position: relative;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 11px 20px;
      min-height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      label {
        p {
          margin: 0;
        }

        span {
          font-size: 14px;
          font-family: "Geometria";
          display: block;
          opacity: 0.8;
        }
      }

      textarea {
        border: 0;
        padding: 0;
        font-size: 16px;
        font-family: $font-bold;
        width: 100%;
        color: $primary-color;
        outline: 0;
      }

      ::placeholder {
        color: $primary-color !important;
      }

      ::-ms-input-placeholder {
        color: $primary-color !important;
      }

      .radio {
        ::placeholder {
          color: #cecece !important;
        }

        ::-ms-input-placeholder {
          color: #cecece !important;
        }
      }
    }
  }
}

.radio {
  width: 100%;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
}

.box-info-check ul li label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: $font-bold;

  input {
    border: none;
    text-align: right;
    color: $primary-color;
    outline: 0;
    background: #fff !important;
  }
}

.radio input[type="radio"] {
  +.radio-label i {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 2px solid #fff;
    position: relative;

    &:before {
      width: 26px;
      height: 26px;
      border-radius: 100%;
      content: "";
      display: flex;
      border: 2px solid #64c9d1;
      margin-top: -2px;
      margin-left: -2px;
    }
  }

  &:checked+.radio-label i {
    &::before {
      background: #64c9d1;
    }

    &::after {
      top: 4px;
      left: 8px;
      box-sizing: border-box;
      width: 6px;
      height: 12px;
      transform: rotate(45deg);
      border-width: 2px;
      border-style: solid;
      border-color: #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      position: absolute;
    }
  }
}

/* HOME */

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #eceff0;
}

.page-header {
  flex: 0 0 auto;
  background-color: #fff;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;

  h4 {
    font-size: 16px;
    font-family: $font-bold;
    margin: 0;
  }
}

.page-content {
  flex: 1 1 auto;
  position: relative;
  background: #eceff0;
  padding-top: 55px;
  padding-bottom: 85px;
}

.page-content-programme {
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  height: calc(100% - 65px);
}

.page-content-workout {
  padding-bottom: 0;
}

.page-footer {
  flex: 0 0 auto;
  background-color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;

  .menu {
    display: flex;
    height: 65px;
    align-items: center;
    justify-content: space-around;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 11px;
    color: #939ea6 !important;
    flex: 1;
    font-family: $font-bold;

    &.active {
      color: #d36b67 !important;
    }

    i {
      display: block;
      background-color: #fff;
      margin: 3px 0;
      background-size: 100%;
      height: 22px !important;

      &.home {
        background-image: url(../images/icn_home.svg);
        width: 18px;
        height: 22px;
        background-repeat: no-repeat;
      }
    }

    &.active i.home {
      background-image: url(../images/icn_home_active.svg);
      width: 18px;
      height: 22px;
      background-repeat: no-repeat;
    }

    i.programme {
      background-image: url(../images/icn_programme.svg);
      width: 26px;
      height: 22px;
      background-repeat: no-repeat;
    }

    &.active i.programme {
      background-image: url(../images/icn_programme_active.svg);
      width: 26px;
      height: 22px;
      background-repeat: no-repeat;
    }

    i.progress {
      background-image: url(../images/icn_progress.svg);
      width: 20px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
    }

    &.active i.progress {
      background-image: url(../images/icn_progress_active.svg);
      width: 21px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
    }

    i.merch {
      background-image: url(../images/icn_merch.svg);
      width: 23px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }

    &.active i.merch {
      background-image: url(../images/icn_merch_active.svg);
      width: 23px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }

    i.blog {
      background-image: url(../images/icn_blog.svg);
      width: 21px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }

    &.active i.blog {
      background-image: url(../images/icn_blog_active.svg);
      width: 21px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }

    i.community {
      background-image: url(../images/icn_community.svg);
      width: 24px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }

    &.active i.community {
      background-image: url(../images/icn_community_active.svg);
      width: 24px;
      height: 22px;
      background-repeat: no-repeat;
      border-radius: 0;
      background-size: 100%;
    }
  }
}

.title-header {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;

  h4 {
    font-family: $font-bold;
    font-size: 16px;
    letter-spacing: 1px;
    color: $primary-color;
    line-height: 23px;
    margin: 0;
    text-align: center;
    flex: 1;

    span {
      // border-bottom: 1px solid $primary-color;
    }
  }
}

.slider {
  margin-bottom: 20px;
  line-height: 0;
  // height: 211px;
  overflow: hidden;

  .main-slider {
    // height: 211px;
    width: 100%;
    position: relative;

    iframe {
      // height: 211px;
      width: 100%;
    }

    .img_slider {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      top: 0;
      background-size: cover;
    }
  }

  .slick-dots {
    bottom: 15px;

    li {
      margin: 0;
      width: auto;
      height: auto;

      button {
        width: auto;
        height: auto;

        &:before {
          content: "";
          background: $primary-color;
          width: 6px;
          height: 6px;
          border-radius: 30px;
          opacity: 0.25;
          display: block;
          position: static;
        }
      }
    }

    li.slick-active button {
      &:before {
        content: "";
        width: 15px;
        opacity: 1;
        transition: all 200ms ease;
      }
    }
  }
}

.button_2 {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;

  button {
    width: 260px;
    text-align: center;
    border-radius: 50px;
    height: 44px;
    border: 0;
    background: #d36b67;
    color: #fff;
    text-transform: uppercase;
    margin: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    font-family: $font-bold;
    letter-spacing: 2px;
    position: relative;
    line-height: 46px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .disable {
    background: rgba(0, 0, 0, 0.1);
    color: #b7b7b7;
  }

  .take_Photo {
    width: 260px;
    text-align: center;
    border-radius: 50px;
    height: 44px;
    border: 0;
    background: #eb6b5d;
    color: #fff !important;
    text-transform: uppercase;
    margin: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    font-family: $font-bold;
    letter-spacing: 2px;
    position: relative;
    line-height: 46px;
    display: block;
    padding: 0 !important;

    input {
      opacity: 0;
      height: 44px;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
}

.box-info-days-sessions {
  padding: 0 20px;
}

.info-days {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px 20px 20px 35px;
  border-radius: 10px;
  font-family: $font-bold;
  position: relative;
  margin-left: 10px;
  margin-bottom: 20px;
  min-height: 100px;

  .icon-days {
    position: absolute;
    left: -35px;
    top: 20px;
  }

  .days {
    font-size: 12px;
    color: $primary-color;
    font-family: $font-bold;
    flex-grow: 1;
    text-align: center;

    span {
      line-height: 26px;
      display: block;
      font-size: 24px;
      color: #64c9d1;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
  }

  .name {
    flex-grow: 3;
    flex-basis: 0;
  }
}

/* PROGRAMME */

.button .button-w220 {
  width: 220px;
  max-width: 100%;
}

.progress_bar_circle {
  display: block;
  background-image: url(../images/bg_programme.svg);
  height: 40vh;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  p {
    font-family: "Roboto";
    color: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 22px;
    margin-top: 60px;
    margin-bottom: 0;

    span {
      font-size: 12px;
      font-family: $font-medium;
      display: block;
      letter-spacing: 0.5px;
      margin-top: 5px;
    }
  }

  svg {
    position: absolute;
  }

  svg path {
    will-change: auto;
    stroke-width: 2px;
    stroke-miterlimit: round;
    transition: stroke-dashoffset 850ms ease-in-out;
  }

  .bg {
    stroke: rgba($color: #fff, $alpha: 0.4);
    stroke-linecap: round;
  }

  .meter {
    stroke-width: 7px;
    stroke: #fce76c;
    stroke-linecap: round;
  }
}

.width_programme_slider {
  display: flex;
  height: calc(60vh - 120px);
  justify-content: center;
  align-items: center;

  >div {
    overflow: hidden;
  }
}

.widthNext img {
  width: 55px;
  height: 55px;
}

@media screen and (max-height: 750px) {
  .progress_bar_circle {
    height: 30vh !important;
  }

  .width_programme_slider {
    height: calc(70vh - 120px) !important;
  }

  .widthNext img {
    width: 40px;
    height: 40px;
  }

  .box_player .time {
    font-size: 40px !important;
  }

  .name_workout p {
    font-size: 16px !important;
  }

  .name_workout span {
    font-size: 14px !important;
  }

  .desc {
    max-height: 130px !important;
  }

  .feedBack .boxMessge textarea {
    min-height: 180px !important;
  }
}

.programme_slider {
  .item {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    background: #fff;
    padding: 20px;
    margin: 20px 10px;
    border-radius: 10px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .owl-stage-outer {
    overflow: unset !important;
    position: relative;
    visibility: visible;
  }

  .lock {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #64c9d1;
    border-radius: 10px 10px 21px 20px;
    width: 38px;
    height: 42px;
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
  }
}

.portfolio .owl-stage-outer {
  overflow: unset !important;
  position: relative;
  visibility: visible;
}

.programme_slider .item h4 {
  font-size: 18px;
  font-family: $font-bold;
  text-align: center;
}

.name_programme {
  height: 200px;
  overflow-y: scroll;

  span {
    margin-top: 4px;
    display: block;
    color: #798790;
    font-family: "Geometria";
    display: flex;
    // align-items: center;

    img {
      width: 18px;
      height: 18px;
      margin-left: 15px;
      position: relative;
      top: 2px;
    }
  }

  p {
    font-family: $font-bold;
    margin-bottom: 0;
  }

  div {
    margin-bottom: 10px;
  }
}

.from-youtube {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.title-main {
  font-family: $font-bold;
  font-size: 16px;
  margin-bottom: 15px;
}

.width_workout_slider {
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

.workout_slider {
  .item {
    width: 260px;
    position: relative;

    h4 {
      position: relative;

      img {
        width: 260px;
        height: 153px;
        border-radius: 10px;
        object-fit: cover;
      }

      span.tick_done {
        position: absolute;
        right: 15px;
        bottom: 15px;
        background: url("../images/ic_tick.svg");
        width: 30px;
        height: 30px;
      }
    }

    p {
      margin-top: 12px;
      font-family: $font-bold;
      margin-bottom: 0;
      color: $primary-color;

      span {
        font-family: "Geometria";
        display: block;
        opacity: 0.8;
      }
    }
  }

  .slick-slide {
    margin-right: 20px;
  }
}

.list-workout {
  padding: 20px;
  margin-top: 10px;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      border-top: 1px solid #e9e5e5;
      padding: 18px 0;

      .img {
        margin-right: 20px;

        img {
          width: 66px;
          height: 85px;
          border-radius: 5px;
          object-fit: cover;
          object-position: top;
        }
      }

      .content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        font-family: $font-bold;
        align-items: center;

        p {
          margin-bottom: 0;
          flex: 4;
        }

        span {
          display: block;
          color: #798790;
          font-family: $font-medium;
        }

        .time {
          display: block;
          color: #798790;
          font-family: "Geometria-Medium";
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}

.duration span {
  color: #798790;
  font-size: 12px;
  font-family: "Geometria";
  margin-right: 20px;
}

.letgo-button {
  position: fixed;
  bottom: 0;
  background-image: url(../images/bg-letgo.png);
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  left: 0;

  a {
    width: 260px !important;
    text-align: center;
    border-radius: 50px;
    height: 44px;
    border: 0;
    background: #64c9d1;
    color: #fff;
    text-transform: uppercase;
    margin: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    font-family: "Geometria-Bold";
    letter-spacing: 2px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* video */

.video-wrapper div.box_video {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 4;
}

.box_player {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.video-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
}

.sound {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: start;
  opacity: 0.8;
  color: #fff;
  height: 90px;

  // background: rgba(0, 0, 0, 0.45);
  // background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.45) 0%, rgba(255, 255, 255, 0) 100%);
  // background: -webkit-gradient(
  //   left top,
  //   left bottom,
  //   color-stop(0%, rgba(0, 0, 0, 0.45)),
  //   color-stop(100%, rgba(255, 255, 255, 0))
  // );
  // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.45) 0%, rgba(255, 255, 255, 0) 100%);
  // background: -o-linear-gradient(top, rgba(0, 0, 0, 0.45) 0%, rgba(255, 255, 255, 0) 100%);
  // background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.45) 0%, rgba(255, 255, 255, 0) 100%);
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0%, rgba(255, 255, 255, 0) 100%);
  // filter    : progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );
  p {
    margin-bottom: 0;
    flex: 1;
    height: 55px;
    opacity: 0.8;
    width: 100%;
    text-align: center;
    line-height: 55px;
  }

  a {
    opacity: 0.8;
  }
}

.player {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  height: 133.33vw;
  width: 100%;
  // background  : rgba(0, 0, 0, 0.16)
}

.box_player .time {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 47px;
  text-align: center;
  //line-height: 0;
  color: #f87034;
}

.player_stop {
  background-color: transparent;
}

.widthPlay {
  margin-left: 30px;
}

.exercise_name {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;

  .button button {
    background: #f87034;
  }
}

.name_workout {
  text-align: center;

  p {
    margin-bottom: 0;
    color: #f87034;
    font-size: 18px;
  }

  span {
    display: block;
  }
}

.prgess-complete {
  display: flex;
  height: 0;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: unset;
  position: fixed;
  z-index: 3;
  width: 100%;

  .progress-bar {
    background: #f87034;
    height: 3px;
    border-radius: 3px;
    position: relative;
    top: 0;
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    span {
      position: relative;
      top: -10px;
      color: #f87034;
    }
  }
}

.content-popup h3 {
  font-size: 15px;
  font-family: $font-bold;
  text-align: center;
  margin: 20px 0;
  line-height: 1.6;
}

.modal-custom {
  .modal-content {
    border-radius: 20px;
    margin: 0 13px;
    border: none;
  }

  .icon {
    position: absolute;
    right: -21px;
    top: -55px;
  }
}

.content-popup .emoji {
  display: flex;
  justify-content: space-around;
  margin: 25px 0 17px;

  a img {
    -webkit-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }

  a.active img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.modal-custom .modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 0;

  button {
    font-family: $font-bold;
    font-size: 14px;
    margin-right: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    border: 0;
    border-radius: 0 0 0 20px;
    width: 50%;
    padding: 16px 20px;
    outline: none;
    color: rgba(0, 0, 0, 0.25);
    background: #f9f9f9;

    &:last-child {
      border-left: 1px solid #dee2e6;
      color: #64c9d1;
      background: transparent;
      border-radius: 0 0 20px 0;
      margin-left: 0;
    }
  }

  .disabled {
    &:last-child {
      color: #798790;
      background: transparent;
      border-radius: 0 0 20px 0;
    }
  }
}

/* PROGRESS */

.page-header-progress {
  .page-header {
    flex-direction: column;
    height: 105px;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  }

  .page-content {
    padding-top: 105px;
  }
}

.bar-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hoverImage {
  opacity: 1 !important;
  display: flex !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.bar-progress-top {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.bar-progress-bottom {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  height: 55px;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav_progress {
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    flex: 1 1;
    height: 50px;
    background-color: #e4e7e8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #939ea6 !important;

    &.active {
      background: #fff;
      border-bottom: 2px solid #64c9d1;
      color: $primary-color !important;
    }
  }
}

.empty__images {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -28px;

  img {
    width: 90%;
  }
}

.empty__images3 {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -53px;
  max-width: 200px;
  text-align: center;

  img {
    width: 63px;
  }

  span {
    max-width: 200px;
    font-size: 13px;
    display: block;
    opacity: 0.5;
  }
}

.empty__images2 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -18px;
  margin-top: -22px;

  img {
    width: 90%;
  }
}

.photos {
  margin-top: 30px;

  h3 {
    font-size: 15px;
    margin: 0 20px 15px 20px;
    font-family: $font-bold;
  }

  .photosContainer {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    display: grid;

    .box {
      position: relative;

      &:after {
        content: "";
        display: block;
        padding-top: 100%;
      }

      .content {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          object-position: top;
        }

        .checked {
          img {
            border: 2px solid #c3635f;
          }
        }
      }
    }
  }
}

.steps span {
  color: #798790;
  font-family: $font-medium;
  margin-top: 5px;
  font-size: 14px;
  margin-left: 5px;
}

.timeline {
  border-left: 1px solid #e0e0e0;
  margin: 30px auto;
  position: relative;
  list-style: none;
  text-align: left;
  max-width: 90%;
  padding-left: 20px;

  .event {
    margin-bottom: 30px;
    position: relative;
  }

  h3 {
    font-size: 15px;
    font-family: $font-bold;
    margin-bottom: 12px;

    span {
      color: #798790;
      font-family: $font-medium;
      margin-top: 5px;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .event {

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 4px;
      left: -25px;
    }

    &:first-child:after {
      background: #fff;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      content: "";
      border: 1px solid #64c9d1;
    }

    &:after {
      background: #64c9d1;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      content: "";
    }
  }

  .div-box-dsc {
    display: block;
    margin-bottom: 10px;
  }

  .box-diary {
    padding: 7px 15px;
    border-radius: 10px;
    background: #fff;
    font-family: "Geometria";
    display: inline-block;
    margin-bottom: 0;

    img {
      display: block;
      width: 30px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .time-heart {
      font-size: 12px;
      font-family: $font-medium;
      color: #777;
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      width: 90px;
    }

    p {
      margin-bottom: 0;
      font-family: $font-bold;
    }
  }
}

/* PAGE CHILE */

.icon_close {
  position: absolute;
  right: 0;
  z-index: 5;
}

.colm_upgrade {
  align-items: center;
  padding: 0;
  background: url("../images/bgSubscription.jpg") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  overflow-x: hidden;
  height: 100%;
  justify-content: center;
  color: #fff;

  @media screen and (max-height: 630px) {
    justify-content: start;
  }

  &::before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }
}

.colm_upgrade_title {
  h4 {
    font-size: 16px;
    font-family: "Geometria-Bold";
  }

  p {
    font-size: 12px;
    color: #798790;
    line-height: 23px;
    font-family: "Geometria";
  }

  h4 span {
    border-bottom: 1px solid $primary-color;
  }
}

.box_upgrade {
  background: #c3635f;
  padding: 15px 20px 13px;
  border-radius: 10px 10px 0 0;
  font-family: "Geometria-Bold";
  font-size: 18px;
  color: #fff !important;
  width: 100%;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
}

p.bg {
  font-size: 12px;
  color: #fff;
  display: block;
  background: #d36b67;
  padding: 14px 20px 13px;
  margin: 0;
}

.box_upgrade_spec {
  background: #d36b67;
  border: 1px solid #d36b67;
  border-radius: 10px 10px 0 0;
  width: 100%;
  margin: 10px 0 0;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  h4 {
    font-family: $font-bold;
    font-size: 18px;
    color: #fff;
    background: #c3635f;
    padding: 15px 20px;
    margin-bottom: 0;
    line-height: 28px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 12px;
    font-style: normal;
    color: #d36b67;
    padding: 3px 9px;
    font-family: $font-bold;
  }

  h4 span {
    font-size: 12px;
    margin-top: 6px;
  }

  p {
    color: #fff;
    padding: 12px 20px 14px;
    margin: 0;
    line-height: 1.6;
    font-size: 12px;

    span {
      font-family: $font-bold;
    }
  }
}

.links_free {
  width: 100%;
  text-align: center;
  padding: 8px 0 25px;

  a {
    color: #d36b67;
    font-family: $font-bold;
    border-bottom: 1px solid #d36b67;
  }
}

.colm_costume_loading {
  align-items: flex-start;
  justify-content: space-between;
  background: #daecee;
  background-image: url(../images/bg_loading.png);
  background-size: cover;
  padding: 40px 20px;

  p {
    font-size: 16px;
    color: #6c7981;
  }

  h4 {
    font-size: 26px;
    font-family: $font-bold;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
  }
}

.loading_progress {
  width: 100%;

  .progress {
    height: 53px;
    background: #c8dbde;
    border-radius: 30px;
  }

  .progress-bar {
    font-size: 16px;
    font-family: $font-bold;
    letter-spacing: 0.5px;
    background: #fff;
    color: $primary-color;
  }
}

/* calendar */

.schedule {

  abbr[data-original-title],
  abbr[title] {
    text-decoration: none;
  }

  .react-calendar__month-view__weekdays {
    font-size: 10px;
    font-family: $font-bold;
    font-weight: 400;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
  }

  padding: 20px;
  margin-top: 25px;

  .react-calendar {
    border: none;
    font-family: $font-medium;
    border-radius: 10px;
    width: 100%;
  }

  .react-calendar__tile--active {
    background: transparent;
    color: inherit;
  }

  .activeDay {
    background: transparent;

    abbr {
      font-family: $font-medium;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .react-calendar__tile {
    padding: 10px 6px;
  }

  .react-calendar__navigation__label {
    cursor: not-allowed;
    pointer-events: none;
  }

  .react-calendar__navigation__arrow {
    background: transparent !important;

    &:hover {
      color: #64c9d1;
    }
  }

  // .highline abbr {
  //   border: 2px solid #c3635f;
  //   color : #c3635f !important;
  // }
  .complete_workout abbr {
    background: #f2f2f2 !important;
    position: relative;

    &::before {
      font-family: "Geometria-Bold";
      background: #64c9d1;
      color: #fff;
      font-size: 10px;
      position: absolute;
      padding: 3px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 11px;
      text-align: center;
    }

    &::before {
      // content:'✔';
      content: "W";
      right: 0;
      top: 0;
    }
  }

  .complete_cardio abbr {
    background: #f2f2f2 !important;
    position: relative;

    &::after {
      font-family: "Geometria-Bold";
      background: #64c9d1;
      color: #fff;
      font-size: 10px;
      position: absolute;
      padding: 3px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 11px;
      text-align: center;
    }

    &::after {
      content: "C";
      left: 0;
      bottom: 0;
    }
  }

  .highline abbr {
    background: #64c9d1 !important;
    position: relative;
    color: #fff !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    abbr {
      color: #c3c3c3 !important;
    }
  }

  .react-calendar__month-view__days {
    button {
      abbr {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 50%;
        color: #324755;
      }
    }
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: transparent;

    abbr {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 50%;
      color: #324755;
      background-color: #e6e6e6;
    }
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: transparent;
  }
}

.calendar-container {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.calendar-table__row {
  display: flex;
  justify-content: space-around;
}

.calendar-table__col {
  font-size: 12px;
  color: #7e7e7f;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}

.calendar_day {
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: auto;

  span.active {
    background-color: #64c9d1;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
  }

  &:hover {
    span {
      background-color: $primary-color;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
    }
  }
}

.schedule-list {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 30px;

  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-top: 1px solid #eeeeee;
    align-items: center;
    min-height: 75px;

    p {
      margin: 0;

      span {
        font-size: 12px;
        color: #8b8b8b;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 15px;
      margin: 0;

      &:before {
        content: "";
        background: #64c9d1;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 13px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.schedule h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  font-family: $font-medium;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #8b8b8b;
    display: block;
    margin-top: 10px;
  }
}

.icon_add {
  left: 50%;
  margin-left: -35px;
  bottom: -36px;
  position: absolute;

  img {
    background-size: 100%;
    width: 70px;
    height: 70px;
  }
}

.box-calendar {
  margin: auto;
  box-shadow: none;

  .form-group:last-child {
    border-bottom: none;
  }

  .button {
    margin-top: 30px;
  }
}

/* Required styles */

.swipe-to-delete {
  position: relative !important;
  padding: 0 !important;
  overflow: hidden !important;

  .js-delete {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 1 !important;
  }

  .js-content {
    position: relative !important;
    z-index: 2 !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
  }

  .js-content :first-child {
    position: relative !important;
  }

  .js-transition-delete-right,
  .js-transition-delete-left,
  .js-transition-cancel {
    transition-property: left !important;
    transition-duration: 0.5s;
  }

  .js-transition-delete-right {
    left: 100% !important;
  }

  .js-transition-delete-left {
    left: -100% !important;
  }

  .js-transition-cancel {
    left: 0 !important;
  }
}

/* Custom styles */

.swipe-to-delete {
  .js-delete {
    background: #ff5f58;
    $horiz-padding: 17px;

    svg {
      position: absolute;
      left: $horiz-padding;
      top: 50%;
      margin-top: -13px;
      width: 25px;
      height: 25px;
    }

    svg:first-child {
      left: auto;
      right: $horiz-padding;
    }
  }

  .js-content :first-child {
    cursor: pointer;
  }

  .js-transition-delete-right,
  .js-transition-delete-left,
  .js-transition-cancel {
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
  }
}

.video-wrapper {
  .background_youtube {
    background-size: contain;
    height: 133.33vw;
    width: 100vw;
    position: absolute;
    top: 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 0 35px;
  }

  >.video_iframe {
    height: 133.33vw;
    width: 100vw;

    div {
      height: 100%;
    }

    iframe {
      pointer-events: none;
      height: 100%;

      .ytp-button {
        display: none;
      }

      .ytp-watermark {
        display: none;
      }
    }
  }
}

.ytp-button {
  display: none;
}

.ytp-watermark {
  display: none;
}

.page_restime {
  background: #64c9d1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: "Geometria-Bold";
  font-size: 13px;
  letter-spacing: 2px;
  height: 100vh !important;
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0;

  .time_restime {
    text-align: left;
    width: 120px;
    margin: auto;

    span {
      display: block;
      font-family: "Roboto", sans-serif;
      font-size: 50px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 50px;
    }
  }

  .skip_time {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 10px;

    a {
      width: 80px;
      color: $primary-color;
    }
  }
}

.congratulations_page {
  background: #fff;
  justify-content: space-between;

  .bgCongra img {
    width: 100%;
  }

  h3 {
    font-size: 31px;
    font-family: $font-bold;
    letter-spacing: 2px;
    color: #eb6b5d;
    text-align: center;
    line-height: 34px;

    span {
      font-size: 17px;
      color: $primary-color;
      display: block;
      letter-spacing: 0;
    }
  }

  .congratulations_btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    text-align: center;

    .button_2 button {
      background: #eb6b5d;
    }

    a {
      color: $primary-color;
      padding: 20px 0;
      font-family: $font-bold;
      font-size: 14px;
      letter-spacing: 1px;

      img {
        margin-left: 4px;
      }
    }
  }
}

.fadeIn {
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  -moz-animation: fadeIn ease 0.3s;
  -o-animation: fadeIn ease 0.3s;
  -ms-animation: fadeIn ease 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.progress_over {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .page_sliderprogess {
    background: #eceff0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
  }

  .title_name {
    margin-left: 20px;
    font-family: $font-bold;
  }

  .image_slider_progress {
    overflow: hidden;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;

    .slick-slider {
      height: 100%;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.withWorkout {
  text-align: center;

  h4 {
    font-family: $font-bold;
    font-size: 18px;
    margin-top: 15px;
  }

  p {
    color: #798790;
  }

  .chooseWith {
    margin: 25px;

    a {
      display: block;
      padding: 12px 17px;
      border: 1px solid #d9d9d9;
      border-radius: 50px;
      margin-bottom: 20px;
      font-family: $font-bold;
      color: $primary-color;
    }
  }
}

.modal-content {
  border: none;
}

.modal-dialog {
  overflow: hidden;
  max-width: 100%;
}

.show.modal-custom-bottom .modal-content {
  bottom: 0;
}

.modal-custom-bottom {
  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    bottom: 0;
    position: relative;
    bottom: -100vh;
    height: 100vh;
    border-radius: 0;
    -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out;

    .modal-body {
      padding: 0;
      background: #fff;
      position: absolute;
      inset: 0px;
      overflow-y: auto;
      width: 100%;
      height: 100%;
    }
  }
}

.loader_page {
  position: fixed;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
}

.loader {
  display: inline-block;
  width: 0;
  position: fixed;
  margin-left: -5px;
  margin-top: -5px;
  top: 50%;
  left: 50%;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #d36b67;
  border-top: 12px solid #64c9d1;
  -webkit-animation: loader 1.2s ease-in-out infinite alternate;
  animation: loader 1.2s ease-in-out infinite alternate;
  z-index: 9999;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(720deg);
  }
}

.icon_camera {
  width: 21px;
  height: 19px;
}

.rate2 ul {
  margin: 0;
  padding: 0;
}

.rate2 ul li {
  display: block;
  position: relative;
  margin: 0 0 5px 0;

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  label {
    display: block;
    position: relative;
    padding: 10px 0 10px 55px;
    font-family: $font-bold;
    z-index: 2;
  }

  .check {
    display: block;
    position: absolute;
    border: 2px solid #939ea6;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 12px;
    left: 20px;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;

    &::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 10px;
      width: 10px;
      top: 3px;
      left: 3px;
      margin: auto;
      transition: background 0.15s linear;
      -webkit-transition: background 0.15s linear;
    }
  }

  input[type="radio"]:checked~.check {
    border: 2px solid #64c9d1;
  }

  input[type="radio"]:checked~.check::before {
    background: #64c9d1;
  }

  textarea {
    margin-left: 20px;
    width: calc(100% - 40px);
    color: #324755;
    outline: none;
    font-size: 15px;
    border: 1px solid #dee2e6;
    min-height: 85px;
    background: #fff;
    border-radius: 10px;
    padding: 7px 17px 9px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:disabled {
      background: #f9f9f9;
    }
  }

  ::placeholder {
    color: $primary-color !important;
  }

  ::-ms-input-placeholder {
    color: $primary-color !important;
  }
}

.select_custom select {
  -webkit-appearance: button;
  appearance: button;
  direction: rtl;
  text-align: right;
  border: none;
  outline: none;
  font-family: $font-medium;
  background: #fff;
  padding: 0;
  color: $primary-color;

  &:invalid {
    color: #c7c7c7;
  }
}

.Profile {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // height: calc(100% - 55px);
  background: #fff;

  ul {
    margin: 0 20px 20px;
    padding: 0;
    width: 100%;

    li {
      display: flex;
      list-style: none;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;
      justify-content: space-between;

      &:last-child {
        border: none;
      }

      a {
        display: block;
        color: #324755;
        padding: 15px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .disableClass {
        opacity: 0.35;

        img {
          opacity: 0.4;
        }
      }

      .assessClass {
        span {
          i {
            color: #fff;
            font-size: 12px;
            background: #c3635f;
            padding: 6px 10px 4px;
            border-radius: 3px;
            font-style: normal;
            margin-left: 5px;
          }
        }
      }

      img {
        opacity: 0.7;
      }
    }
  }

  .logOUt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: $font-bold;

    a {
      height: 55px;
      color: #324755;
      display: flex;
      align-items: center;
      font-family: $font-medium;
    }

    img {
      margin-right: 7px;
    }
  }

  .avatarUser {
    display: flex;
    align-items: center;
    flex-direction: column;

    .boxAvartarUser {
      position: relative;

      p {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          object-position: center;
        }
      }

      span {
        position: absolute;
        right: -20px;
        bottom: 0;

        .file {
          opacity: 0;
          position: absolute;
          z-index: 2;
          height: 55px;
          width: 60px;
        }
      }
    }

    .memberDays {
      margin: 10px 0 0;
      font-size: 12px;
      font-family: $font-medium;
      color: #8b8b8b;
    }

    p {
      font-family: $font-bold;
      font-size: 16px;
      margin-bottom: 2px;
    }

    a.upgrade {
      width: 270px;
      text-align: center;
      border-radius: 50px;
      font-size: 13px;
      height: 44px;
      border: 0;
      background: #d36b67;
      color: #fff;
      text-transform: uppercase;
      margin: auto;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
      font-family: $font-bold;
      letter-spacing: 2px;
      position: relative;
      line-height: 46px;

      &.disable {
        opacity: 0.45;
      }

      img {
        position: relative;
        top: -3px;
        margin-right: 4px;
        fill: #fff;
      }
    }
  }
}

.exerciseTimer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  width: 100%;

  .exercise_name {
    height: auto;

    // position: relative;
    .desc {
      opacity: 0.6;
      padding: 0 20px;
      font-size: 14px;
      font-family: $font-medium;
      max-height: 300px;
      margin-top: 15px;
      overflow-y: scroll;
    }
  }

  .timerCount {
    width: 200px;
    height: 200px;
    margin: 40px auto 0;
    position: relative;

    .circleLine {
      stroke-width: 2px;
    }

    .circleGradient {
      stroke-linecap: round;
      fill: transparent;
      stroke: url(#pattern);
      stroke-width: 3.5px;
      animation: load 10s;
    }

    .timeExercise {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Roboto";
      font-size: 48px;
      font-weight: 500;
      color: #f26d32;
    }
  }

  .button {
    margin-top: 15px;
    margin-bottom: 0;

    a {
      display: block;
      font-family: $font-bold;
      font-size: 14px;
      color: #798790;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;
      width: 132px;
      margin: 25px auto 0;
    }

    button {
      background: #f26d32;
    }
  }

  .imgReps {
    height: 60vh;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.timerPopup {
  h3 {
    margin: 20px 35px 14px;
  }

  .modal-footer button:first-child {
    color: #798790;
    background: transparent;
  }
}

@media screen and (max-height: 630px) {
  .box_video {
    top: -20px;
  }

  .progress_bar_circle {
    svg {
      width: 160px;
    }

    p {
      margin-top: 45px;
    }
  }

  .imgReps {
    height: 50vh !important;
  }

  .name_programme {
    height: 160px;
  }

  .timerCount {
    width: 160px !important;
    height: 160px !important;
    margin-top: 20px !important;

    .timeExercise {
      font-size: 38px !important;
    }
  }

  .exerciseTimer .button {
    margin-bottom: 20px;
  }
}

.thanks_page {
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;

  .title_thanks {
    text-align: center;

    h3 {
      font-family: $font-bold;
      font-size: 40px;

      span {
        font-size: 20px;
        letter-spacing: 0.5px;
        display: block;
        margin-top: 3px;
      }
    }

    p {
      color: #798790;
      margin-top: 30px;
    }
  }

  .button_2 button {
    background: #324755;
  }
}

.progress-bg {
  background: rgba(0, 0, 0, 0.08);
  height: 3px;
  width: 100%;
  margin-right: 3px;
  border-radius: 3px;

  &:last-child {
    margin-right: 0;
    border-radius: 0;
  }
}

.hiddenSkip {
  visibility: hidden;
}

.recharts-responsive-container,
.recharts-wrapper {
  font-size: 10px;
  width: 100%;
  height: 400px;

  .recharts-rectangle {
    width: 20px;
  }

  .recharts-cartesian-axis-tick-line {
    display: none;
  }
}

.recharts-cartesian-axis-line {
  stroke: transparent;
}

.chartHeart {
  width: 90px;
  height: 75px;
  margin-top: 10px;

  .recharts-wrapper {
    border-bottom: 1px dotted #e0e0e0;
  }
}

.line-time {
  padding: 0 4px;
}

.colmResetPassword {
  justify-content: space-evenly;

  ::placeholder {
    color: $primary-color !important;
  }

  ::-ms-input-placeholder {
    color: $primary-color !important;
  }
}

.heartMinMax {
  font-family: $font-bold;
  margin-top: 12px;
  display: flex;
  font-size: 14px;
  align-items: center;

  .heartBeat {
    animation: 0.8s infinite beatHeart;
    margin: 0 10px;
    width: 18px !important;
  }

  @keyframes beatHeart {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1.1);
    }

    40% {
      transform: scale(1);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.pageNomal {
  background: #fff;

  .page-header {
    box-shadow: none;
  }
}

.pageFeedBack {
  background: #fff;
  position: absolute;
  height: 100vh;
  top: 0;
  width: 100%;
  left: 0;
  overflow-y: scroll;

  .page-header {
    box-shadow: none;
  }
}

.feedBack {
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  margin-top: 55px;
  margin-bottom: 30px;
  position: absolute;
  height: calc(100vh - 55px);
  inset: 0px;
  overflow-y: auto;
  width: 100%;

  .tuyenmessage {
    font-size: 13px;
    opacity: 0.6;

    p {
      margin-bottom: 3px;
    }
  }

  .fbUpload {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-gap: 20px;
    width: 100%;

    .btnUPload {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;

      .fCamera {
        font-size: 13px;
        display: flex;
        align-items: center;
        height: 50%;
        line-height: 18px;
        color: #6c7981;
        margin: auto;
        width: 100%;
        justify-content: center;
        position: relative;
        text-align: center;
        flex-direction: column;
        opacity: 0.6;
        margin-bottom: 0;

        input {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        img {
          opacity: 0.5;
        }
      }

      a {
        font-size: 13px;
        display: flex;
        align-items: center;
        height: 50%;
        line-height: 18px;
        color: #6c7981;
        margin: auto;
        width: 100%;
        justify-content: center;
        position: relative;
        text-align: center;
        flex-direction: column;
        opacity: 0.6;

        &:last-child::before {
          width: 80%;
          content: "";
          height: 2px;
          position: absolute;
          top: 0;
          border-top: 2px dashed #dee2e6;
        }
      }

      img {
        width: 20px;
        margin-bottom: 7px;
      }
    }

    .boxImgUpload {
      overflow: hidden;

      .classNoImgUpload {
        border: none !important;

        img {
          width: 100% !important;
          height: 100% !important;
        }
      }

      h4 {
        font-size: 15px;
        text-align: center;
        font-family: $font-bold;
        margin: 0 0 10px;
      }

      .imgUpload {
        background: #f9f9f9;
        border-radius: 10px;
        height: 190px;
        border: 2px dashed #dee2e6;
        overflow: hidden;
        position: relative;

        .cancelImages {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 0;
          right: 0;
          z-index: 99;
          text-align: center;
          line-height: 38px;

          img {
            width: auto !important;
            height: auto !important;
          }
        }

        .icUpload {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          flex-direction: column;
          font-size: 13px;
          color: $primary-color;
          font-family: $font-bold;

          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            object-position: top;
          }
        }
      }
    }
  }

  .boxMessge {
    margin: 30px 0 25px;
    width: 100%;

    h4 {
      font-size: 15px;
      font-family: $font-bold;
      margin: 0 0 10px;
    }

    textarea {
      width: 100%;
      color: #324755;
      background: #f9f9f9;
      outline: none;
      font-size: 15px;
      border: 1px solid #dee2e6;
      min-height: 275px;
      border-radius: 10px;
      padding: 7px 17px 9px;

      &::placeholder {
        color: #6c7981 !important;
      }

      &::-ms-input-placeholder {
        color: #6c7981 !important;
      }
    }
  }

  .button {
    padding-bottom: 30px;
  }
}

.privacy {
  padding: 10px 20px;
  margin-top: 55px;
  position: absolute;
  height: calc(100vh - 55px);
  inset: 0px;
  overflow-x: hidden;
  width: 100%;

  a {
    color: #64c9d1;
  }

  h3 {
    font-size: 20px;
  }
}

.styleChart::-webkit-scrollbar {
  display: none;
}

.disableInputDate {
  opacity: 0.3;
}

.daysTotalChart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $font-bold;
  font-size: 13px;
  background: #fff;
  margin: 0 20px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.035);

  .disable img {
    opacity: 0.3;
  }

  a {
    width: 80px;
    display: flex;
    padding: 20px;

    img {
      width: 7px;
    }

    &:first-child {
      justify-content: flex-end;
    }
  }
}

.kmTotalChart {
  display: flex;
  margin: 0 20px;
  line-height: 25px;
  padding: 10px 0 0;
  flex-direction: column;

  p {
    font-size: 12px;
    color: #8b8b8b;
  }

  span {
    display: block;
    font-family: $font-bold;
    font-size: 22px;
  }
}

.styleChart {
  height: 370px;
  padding: 50px 0 60px;
  background: #fff;
  margin: 0 20px;
  border-radius: 0 0 5px 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  direction: rtl;

  .weeksChart {
    height: 100%;
    width: 100%;
    display: inline-block;
  }

  .styleHeight {
    &:hover {
      &::after {
        display: none !important;
      }
    }

    .stepsChart {
      display: none !important;
    }
  }

  .testClass {
    height: 100%;
    display: inline-block;
    position: relative;
    width: auto;

    .styleColumn {
      width: 47px;
    }
  }

  .styleColumn {
    height: 100%;
    display: inline-block;
    position: relative;
    width: calc(100% / 7);

    &::before {
      content: "";
      width: 1px;
      height: 310px;
      position: absolute;
      top: -50px;
      left: 0;
      border-right: 1px dashed rgba(0, 0, 0, 0.12);
    }

    &:hover {
      .stepsChart {
        display: block;
      }

      .dayChart span {
        opacity: 1;
      }

      &::after {
        content: "";
        width: 2px;
        height: 300px;
        position: absolute;
        top: -35px;
        left: 50%;
        border-right: 2px solid #64c9d1;
        transform: translate(-50%, 0);
      }
    }

    .chartBar {
      height: 100%;
      border-radius: 3px 3px 0 0;
      background: #64c9d1;
      position: absolute;
      bottom: 0;
      padding: 0 8px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &:first-child {
      .stepsChart {
        left: auto;
        right: 50%;
        transform: translate(12%, 0);

        &::after {
          left: auto;
        }
      }
    }

    .stepsChart {
      display: none;
      font-size: 14px;
      position: absolute;
      top: -35px;
      left: 50%;
      background: #64c9d1;
      padding: 3px 9px 2px;
      z-index: 4;
      border-radius: 3px;
      color: #fff;
      transform: translate(-12%, 0);
    }

    .dayChart {
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      bottom: -68px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: $font-bold;

      span {
        color: $primary-color;
        opacity: 0;
        text-transform: capitalize;
        font-family: $font-bold;
        font-size: 13px;
      }
    }
  }
}

.hearChart {
  height: 320px;
  padding: 60px 0 60px;
  position: relative;
  z-index: 1;

  .styleHeight {
    &:hover {
      &::after {
        display: none !important;
      }
    }

    .stepsChart {
      display: none !important;
    }
  }

  .styleColumn {
    &:nth-child(6) .stepsChart {
      left: auto;
      right: 50%;
      transform: translate(12%, 0);
    }

    &::before {
      height: 250px;
    }

    .stepsChart {
      background: #d36b67;
      top: -60px;
      padding: 3px 10px 2px;

      .heartMM {
        display: flex;
        flex-direction: row;
        direction: ltr;

        div {
          display: flex;
          flex-direction: column;
          margin-right: 10px;

          p {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }

    .chartBar {
      padding: 0 4px;
      border-radius: 10px;
      bottom: auto;
      background: #d36b67;
    }

    &:hover {
      &::after {
        border-right: 2px solid #d36b67;
        height: 240px;
      }
    }
  }
}

.changePassword {
  label {
    font-size: 14px;
    margin: 0;
  }

  input {
    padding: 0;

    &:focus {
      border-color: #ced4da !important;
    }
  }
}

.modalProfileChild .modal-dialog {
  margin: 0;
  transition: transform 0s ease-out !important;
  background: #fff;
  transform: none !important;
  height: 100%;
  max-width: 100%;
}

.modalListAssessment .modal-dialog {
  height: 100%;

  .listAssessement {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      border-bottom: 1px solid #f1f1f1;

      &:last-child {
        border: none;
      }

      a {
        display: flex;
        position: relative;
        color: #798790;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
        padding: 16px 20px;

        p {
          margin-bottom: 5px;
          font-size: 15px;
          color: $primary-color;
          font-family: $font-bold;

          span {
            color: #fff;
            font-size: 12px;
            background: #64c9d1;
            padding: 6px 10px 4px;
            border-radius: 3px;
            font-style: normal;
            margin-left: 5px;
          }
        }

        .arrow {
          margin: 0 0 0 30px;
          opacity: 0.7;
        }
      }
    }
  }
}

.msgerChat {
  flex: 1;

  .msgBubbleImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    height: 220px;

    h4 {
      font-size: 15px;
      text-align: center;
      font-family: "Geometria-Bold";
      margin: 0 0 10px;
    }

    img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      height: 190px;
    }
  }

  .msg {
    display: flex;
    margin-bottom: 10px;
    position: relative;

    .msgBubble {
      white-space: pre-line;
      max-width: 450px;
      padding: 12px 19px;
      border-radius: 10px 10px 10px 0;
      background: #f2f3f7;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .msgImage {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      bottom: -33px;

      img {
        width: 100%;
      }
    }
  }

  .msgLeft {
    margin-right: 30px;
  }

  .msgRight {
    flex-direction: row-reverse;
    margin-top: 55px;
    margin-left: 30px;

    .msgBubble {
      background: #c3635f;
      color: #fff;
      border-radius: 10px 10px 0 10px;
    }
  }
}

.photosApp {
  margin-top: 35px;
}

.textUse {
  color: #c3635f;
}

.rdtPicker table {
  color: $primary-color;
}

.red {
  background: #d36b67;

  h2 {
    color: #ffffff !important;
    border: none !important;
  }
}

.getDay {
  border-top: 0;
  border-radius: 0 0 20px 20px;
  width: 100%;
  margin: 0 0 10px;
  text-align: center;

  h2 {
    font-family: "Geometria-Bold";
    font-size: 16px;
    color: #c3635f;
    padding: 20px 0;
    margin-bottom: 0;
    border: 1px solid #dbdbdb;
    border-top: 0;
    border-bottom: 0;
  }

  span {
    background: #c3635f;
    color: #fff;
    display: block;
    font-weight: 600;
    border-radius: 0 0 10px 10px;
    padding: 15px 20px;
    color: #fff;
    text-transform: uppercase;
    margin: auto;
    font-family: "Geometria-Bold";
    letter-spacing: 2px;
    position: relative;

    small {
      display: block;
      letter-spacing: 0;
      font-size: 12px;
      font-family: $font-medium;
      text-transform: capitalize;
    }
  }
}

.listUpgrade {
  margin: 0;
  padding: 0;
  border: 1px solid #dbdbdb;
  border-radius: 0 0 10px 10px;
  border-top: none;

  li {
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-gap: 30px;
    align-items: center;
    font-size: 14px;
    padding: 11px 15px;
    position: relative;

    &:nth-child(2n) {
      background: #f2f2f2;
    }

    img {
      margin: auto;
      width: 16px;
    }
  }

  .disable {
    opacity: 0.6;
  }
}

.sliderUpgrade {
  width: 100%;
  .slick-list {
    z-index: 3;
  }

  .offer {
    position: absolute;
    right: 20px;
    margin-top: -125px;

    img {
      width: 140px;
    }

    @media screen and (max-height: 670px) {
      top: 15px;
      margin-top: 0;

      img {
        width: 95px;
      }
    }
  }

  .contentSub {
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    display: flex !important;
    // height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: auto;

    &.padding {
      padding: 0 35px;
    }

    h1 {
      font-size: 32px;
      font-weight: 800;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    h3 {
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 800;
      margin-top: 0;
      padding: 0;

      span {
        font-size: 15px;
        text-decoration: line-through;
        border: none;
      }

      i {
        font-weight: bold;
        color: #ef474b;
        font-style: normal;
      }
    }

    h5 {
      margin-top: 15px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;

      i {
        text-decoration: line-through;
        font-style: normal;
        font-size: 15px;
        color: white;
      }

      span {
        display: block;
        font-size: 12px;
        color: white;
        margin-top: 5px;
      }
    }

    .uppercase {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
    }

    ul {
      text-align: left;
      margin: 20px auto 0;
      max-width: 280px;

      li {
        margin-top: 8px;
        font-size: 14px;
      }
    }

    hr {
      background: #fff;
      margin: 23px auto 18px;
      max-width: 280px;
    }

    p {
      margin-bottom: 11px;
    }

    .large {
      font-size: 18px;
    }

    .button {
      border: 2px solid #fff;
      background: transparent;
      width: 220px;
      height: 44px;
      display: flex;
      margin: 30px auto 0;
      line-height: 39px;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      position: relative;
      z-index: 33;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

      &:focus,
      &:hover {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .slick-dots {
    margin: 17px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 0;

    li {
      margin: 0;
      width: auto;
      height: auto;

      button {
        width: auto;
        height: auto;

        &:before {
          content: "";
          background: #fff;
          width: 12px;
          height: 12px;
          border-radius: 30px;
          opacity: 0.25;
          display: block;
          position: static;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }

    li.slick-active button {
      &:before {
        content: "";
        opacity: 1;
        transition: all 200ms ease;
      }
    }
  }
}

.formSchedule {
  h4 {
    text-align: center;
    font-size: 16px;
    font-family: "Geometria-Bold";
    margin: 20px 0 0;
  }

  .form-group {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 21px 0;
    border-bottom: 1px solid #f0f3f4;
    margin: 0 15px;

    label {
      margin: 0;
    }

    input,
    select {
      border: 0;
      text-align: right;
      font-family: "Geometria-Bold";
      font-size: 15px;
      color: #324755;
      background: transparent;
      padding: 0;
    }
  }

  .button {
    margin-top: 25px;
  }
}

.page-content .schedule .complete_cardio abbr,
.page-content .schedule .complete_workout abbr {
  color: #324755 !important;
  background: #f2f2f2 !important;
}

.buttoncalendar {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    margin: 0;
  }

  .buttonDisable {
    background: #f2f2f2;
    color: $primary-color;
  }
}

.cardiooutside {
  margin: 0 20px 30px;

  .box {
    background: #fff;
    border-radius: 10px;
    padding: 20px 20px 15px;

    text-align: center;

    p {
      font-family: $font-bold;
    }
  }

  .button {
    button {
      width: auto;
      padding: 0 30px;
    }
  }
}

.cardio {
  margin: 20px;
  display: flex;
  flex-direction: column;

  .date_cadio input {
    background: transparent;
    border: none;
    // padding: 0 20px;
    font-weight: 600;
    color: #324755;
  }

  .error {
    font-size: 13px;
    padding-right: 30px;
    opacity: 0.8;
    color: red;
  }

  ul {
    margin: 0 10px 0;

    li {
      margin: 8px 0;

      .enter-active {
        position: relative;
        margin-top: 7px;
      }

      textarea {
        margin-left: 0;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        min-height: 20px;
        padding: 0 0 5px;
        resize: none;
        margin-bottom: 10px;
        line-height: 28px;
      }

      .check {
        left: 0 !important;
      }

      label {
        padding-left: 35px !important;
        margin: 0;
      }
    }
  }
}

.ProgressBarRest {
  margin-top: 5px;
  position: fixed;
  bottom: 0px;
  width: 100%;

  .progress {
    display: flex;
    height: 7px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #f2f2f2;
    border-radius: 0;

    .bg-success {
      background: #4caf50 !important;
    }

    .bg-danger {
      background: #f44336 !important;
    }
  }
}

@media screen and (max-width: 340px) {
  .schedule .react-calendar__month-view__days button abbr {
    font-size: 13px;
    width: 32px;
    border-radius: 5px;
  }
}

.bg {
  background: rgba(0, 0, 0, 0.5);
}

.button-timer {
  width: auto !important;
  padding: 0 40px;
}

.center-on-page {
  position: fixed;
  top: 30px;
  font-weight: 800;
  font-size: 30px;
  right: 30px;
  letter-spacing: 2px;
  color: #f26d32;
}

.heart {
  animation: pulse 1s infinite;
}

@keyframes pulse {

  0%,
  20% {
    transform: rotate(0) scale(1);
  }

  30% {
    transform: rotate(0) scale(1.2);
  }

  50% {
    transform: rotate(0) scale(1);
  }

  60% {
    transform: rotate(0) scale(1.1);
  }

  70%,
  100% {
    transform: rotate(0) scale(1);
  }
}

.alert {
  margin: 20px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-self: center;

  img {
    margin-right: 8px;
  }
}

.subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .subbutton {
    background: #fff;
    border-radius: 30px;
    border: none;
    padding: 6px 15px;
    outline: none;
    color: #c3635f;
    font-size: 13px;
  }
}

.button.btnworkouts {
  margin-bottom: 0;

  button {
    margin-top: 15px;
  }

  a {
    font-family: "Geometria-Bold";
    font-size: 14px;
    color: #798790;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    background: transparent;
    border: 0;
    width: 50%;
    padding: 16px 20px 0;
    outline: none;
    margin: 0 auto;
    display: block;
  }
}

.coutDown {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  top: 50%;

  @media screen and (max-height: 750px) {
    margin-top: -50px;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
    opacity: 0.35;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #000;
    opacity: 0.35;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: #fff;
    font-size: 40px;
    font-family: "Geometria-Bold";
    dominant-baseline: middle;
    text-anchor: middle;
    text-shadow: 0 6px 8px #222, 0 -2px 1px #fff;
  }
}

.link-emailto {
  text-align: center;
  font-size: 14px;
  margin-top: 65px;

  a {
    display: block;
    margin-top: 10px;
    font-size: 16px;
  }
}

.backInfo .back {
  position: fixed;
  top: 0;
  left: 0;
}

.youtubeExpired {
  margin-bottom: 20px;
  position: relative;

  .img_slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.changeWorkout {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-height: 650px) {
    justify-content: start;
  }
}

.mess {
  background: #fff;
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;

  .button {
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.curentfocus {
  text-align: center;

  span {
    font-size: 14px;
    color: #777;
  }

  p {
    text-transform: uppercase;
    font-family: $font-bold;
    margin-bottom: 0;
  }
}

.button-newversion {
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;

  p {
    font-size: 14px;
    margin-bottom: 7px;
  }

  button {
    background: #f87034;
    width: 180px;
  }
}

.current-w {
  padding: 0 20px;
  margin-bottom: 20px;

  .box {
    background: #fff;
    width: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Geometria-Medium";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
      font-size: 14px;
      font-weight: 400;
      display: block;
      border-left: 1px solid #e8e8e8;
      margin-bottom: 0;
      margin-left: 20px;
      padding-left: 20px;
    }
  }
}

.errorAssessment {
  text-align: center;
  max-width: 250px;
  line-height: 17px;
}

.main-slider {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 0;
  height: 0;
}

.main-slider iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.zoomInAnimated {
  font-size: 95px;
  font-family: "Geometria-Bold";
  font-weight: bold;
  color: #64c9d1;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  span {
    animation: crescendo 1s alternate ease-in;
    animation-delay: 0s;
    visibility: hidden;
    position: absolute;
  }

  span:nth-child(2) {
    animation-delay: 1s;
  }

  span:nth-child(3) {
    animation-delay: 2s;
  }

  span:nth-child(4) {
    animation-delay: 3s;
    color: #8ec940;
  }
}

@keyframes crescendo {
  0% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: scale(5);
    opacity: 0;
  }
}

.closeUpgrade {
  left: 0;
  right: auto;

  img {
    width: 19px;
  }
}

.blue {
  background: #324755 !important;
}

.achievements {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Geometria-Bold";
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 0;

  .content {
    position: relative;

    .imgLarge {
      margin: 30px auto;
      text-align: center;
      max-width: 100%;

      img {
        width: 100%;
      }

      @media screen and (max-height: 670px) {
        width: 280px;
        margin: 10px auto;
      }
    }

    .button_2 {
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    h1 {
      font-size: 34px;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;
      margin-top: 12px;

      &::after {
        width: 165px;
        height: 25px;
        background: #c5fff2;
        margin: auto;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -82px;
        margin-top: -20px;
        z-index: -1;
      }
    }

    h3 {
      font-size: 18px;
      margin-bottom: 17px;
    }

    p {
      color: #798790;
      line-height: 28px;
      font-family: "Geometria";
      margin-top: 14px;

      span {
        color: #324755;
        display: block;
        margin-top: 20px;
        font-family: "Geometria-Bold";
      }
    }
  }

  .starConfetti {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -20px);
  }
}

.achievementsList {
  h4 {
    font-size: 16px;
    font-family: "Geometria-Bold";
    margin: 0;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 25px 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding-top: 10px;

      .score {
        width: 47px;
        height: 30px;
        background: url("../images/laurel_score.svg");
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        color: #fbb419;
        font-family: "Geometria-Bold";
        font-size: 21px;
        line-height: 12px;
      }

      h5 {
        font-size: 14px;
        color: #324755;
        font-family: "Geometria-Bold";
      }

      a {
        display: flex;
        align-items: center;
        flex-direction: column;

        .image {
          margin-bottom: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }

        p {
          text-align: center;
          color: #798790;
          font-family: "Geometria";
          font-size: 12px;
          margin: 0;
        }
      }
    }

    .disable {
      opacity: 0.5;
      filter: blur(2px);
      -webkit-filter: blur(2px);

      a {
        img {
          -webkit-filter: grayscale(100%);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          opacity: 0.65;
        }
      }
    }
  }
}

.adward {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  color: #ec9922 !important;
  text-decoration: underline !important;

  img {
    width: 20px;
    margin-right: 3px;
  }

  .rotate {
    transform: scaleX(-1);
  }
}

.page-header {
  .adward {
    text-decoration: none !important;
  }
}

.logoShare {
  margin: 0 auto 20px;

  img {
    width: 185px;
  }

  a {
    color: #324755;
    text-decoration: none;
    font-weight: 400;
    font-family: $font-medium;
    position: relative;
    top: -4px;
  }
}

.joinMember {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-gap: 12px;
  align-items: flex-start;
  color: #586f7d !important;
  background-color: #ffebed;
  margin: 0 20px;
  padding: 12px;

  h3 {
    font-family: $font-bold;
    color: #324755 !important;
    font-size: 18px;
    margin: 0 0 4px;
  }

  img {
    width: 100%;
    margin-top: 2px;
  }
}

.joinMember2 {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-gap: 12px;
  align-items: flex-start;
  margin: 0 20px;
  padding: 12px;
  color: #324755 !important;
  background-color: rgba(100, 201, 209, 0.2);
  border-color: rgba(100, 201, 209, 0.3);

  h3 {
    font-family: $font-bold;
    font-size: 18px;
    margin: 0 0 4px;
    color: #324755 !important;
  }

  img {
    width: 100%;
    margin-top: 2px;
  }
}

.achivementClass {
  padding: 30px 20px 40px;
}

.custom-swicth-modal {
  padding: 15px 0;
  width: 100%;

  .custom-control-label {
    width: 100%;
    padding-right: 75px;

    &::before {
      background-color: #d1d4d8;
      border: none;
      right: 0;
      left: auto;
      width: 40px;
      height: 21px;
      border-radius: 23px;
      top: 50%;
      margin-top: -11px;
    }

    &::after {
      right: 22px;
      left: auto;
      height: 15px;
      width: 15px;
      top: 50%;
      margin-top: -8px;
      background-color: #fff;
    }
  }

  .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(19px);
    transform: translateX(19px);
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #d36b67;
    background-color: #d36b67;
  }
}

.videoIntro {
  width: 100vw;
  height: 100vh !important;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}


.react-transform-component {
  overflow: inherit !important;
}

.react-transform-element {}

.progress-img {
  pointer-events: auto !important;
}



.recipesMain {
  width: 100%;
  padding: 0 16px;
  max-width: 600px;
  margin: 55px auto 0;
  padding-bottom: 33px;

  +.button {
    position: fixed;
    padding: 20px 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    background: #fff;
    margin-bottom: 0;

    button {
      width: 100%;
      max-width: 340px;
      height: 40px;
      margin: auto;
      font-size: 13px;
    }
  }

  .image {
    width: 100%;
    height: 214px;
    border-radius: 10px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  p {
    font-family: $font-medium;
    font-size: 18px;
    color: $primary-color;
    margin-top: 10px;

    span {
      font-size: 14px;
      display: block;
      color: #707070;
      font-family: 'Geometria';
    }
  }

  .content {
    font-family: 'Geometria';
    line-height: 1.9;

    .title-main {
      margin-top: 20px;
      border-top: 1px solid #e8e8e8;
      padding-top: 20px;
    }

    .instructions {
      margin: 0;
      padding: 0;

      li {
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-gap: 16px;
        line-height: 1.5;
        margin-bottom: 15px;
      }

      span {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $primary-color;
        font-size: 12px;
        font-weight: 600;
        margin-top: 3px;
      }
    }
  }

  .ttchild {
    line-height: 1.4;
    font-weight: bold;
    margin: 20px 0 10px;
    font-size: 15px;

    &::before {
      content: '-';
      margin-right: 5px;
    }
  }

  .note {
    line-height: 1.5;
    font-size: 14px;
    font-style: italic;
  }
}

.sliderRecipes {
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    position: relative;


    &.lock {
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        background: #64c9d1;
        border-radius: 10px 10px 21px 20px;
        width: 25px;
        height: 28px;
        display: flex;
        justify-content: center;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
        background-image: url(../images/lock.svg);
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
      }
    }

    &:last-child {
      padding-right: 16px;

      &.lock {
        &::before {
          right: 26px;
        }
      }
    }

    margin-left: 16px;

    p {
      font-weight: 500;
      color: $primary-color;
      font-family: $font-medium;
      margin-top: 7px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 145px;
      white-space: nowrap;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .image {
      width: 145px;
      height: 145px;
      border-radius: 10px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.header-recipes {
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  transform: translate(-50%, 0);
  left: 50%;

  a {
    color: $primary-color;
    font-family: 'Geometria';
    margin-left: 16px;
    font-size: 13px;
    position: relative;
    top: -2px;
  }

  img {
    margin: 0 8px;
    opacity: 0.4;
  }
}


span.newicon {
  position: absolute;
  top: 10px;
  right: -23px;
  font-size: 12px;
  font-family: $font-bold;
  color: #d36b67;
}

@media screen and (min-width: 768px) {
  .recipesMain .image {
    height: 100%
  }

}

.textred {
  color: #d36b67;
}

.textbellow {
  font-size: 13px;
  font-weight: 400;
  color: #798790;
  display: block;
  line-height: 1.4;
  font-family: $font-medium
}

.tags {
  position: sticky;
  top: 50px;
  background: #fff;
  z-index: 999;
  padding: 0 20px 10px;
  width: 100%;
  top: 55px;

  ul {
    margin: 10px 0 0;
    padding: 0;

    li {
      display: inline-block;
      background: #eceff0;
      border-radius: 30px;
      color: $primary-color;
      padding: 3px 0 3px 12px;
      margin-right: 11px;
      margin-bottom: 11px;
      font-size: 13px;
      a {
        padding: 0 9px 0 5px;
        img {
          padding: 2px;
          width: 13px;
          height: 13px;
          opacity: 0.5;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .button {
    margin-top: 10px;
    a {
      width: 260px !important;
      text-align: center;
      border-radius: 50px;
      height: 44px;
      border: 0;
      background: #64c9d1;
      color: #fff;
      text-transform: uppercase;
      margin: auto;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
      font-family: "Geometria-Bold";
      letter-spacing: 2px;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}